<template>
  <el-container direction="vertical" :class="[$route.name]">
    <tt-common-header :menu="menu"></tt-common-header>
    <ts-main v-if="menu.categoryWebmaintpl === 'Item'" :menu="menu"> </ts-main>
    <tt-common-footer :menu="menu"></tt-common-footer>
  </el-container>
</template>

<script>
import TsMain from "./Main";
import { mapState } from "vuex";
export default {
  name: "TtMain",
  components: {
    TsMain,
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
};
</script>
