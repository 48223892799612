import axios from "axios";
import _ from "lodash";
import { Message } from "element-ui";
import page from "@/components/common/page";

// let isMessageVisible = false;
// let $CONFIG = null;
let $STORE = null;

const ins = axios.create({
  timeout: 30000,
});

ins.interceptors.request.use(
  (config) => {
    let baseInfo = $STORE.state.baseInfo;
    if (!config.url.endsWith("/site/common/info") && !!baseInfo)
      config.headers = {
        ...config.headers,
        site: baseInfo.site,
      };
    // let token = $STORE.state.token;
    // if (!config.url.endsWith("/oauth/token")) {
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      withCredentials: true,
    };
    // }
    // if (token) {
    //   config.headers = {
    //     ...config.headers,
    //     Authorization:
    //       _.upperFirst(token.token_type) + " " + token.access_token,
    //   };
    // }
    return config;
  },
  (error) => {
    let response = error.response;
    return Promise.reject(response);
  }
);

ins.interceptors.response.use(
  (response) => {
    if (typeof response.data.code != "undefined") {
      if (response.data.code === 0) {
        // } else if (response.data.code === 4003) {
        //   apis.expiredToken(response);
        // } else if (response.data.code === 4001) {
        //   return apis.refreshToken(response);
      } else {
        apis.callFailure(response.data);
      }
    }
    return response;
  },
  (error) => {
    let response = error.response;
    // if (response.status === 400) {
    //   apis.expiredToken(response);
    // } else if (response.status === 401) {
    //   return apis.refreshToken(response);
    // } else {
    apis.errorRequest(response);
    // }
    return Promise.reject(response);
  }
);

const apis = {
  install: (Vue, options) => {
    // $CONFIG = options.config;
    $STORE = options.store;
  },

  callSuccess: (res) => {
    Message({
      showClose: true,
      center: true,
      message: res.msg,
      type: "success",
    });
  },
  callFailure: (res) => {
    Message({
      showClose: true,
      center: true,
      message: res.msg,
      type: "warning",
      // onClose: () => {
      //   isMessageVisible = true;
      // },
    });
  },
  errorRequest: (response) => {
    let status = response.status;
    if (status === 200) {
      status = response.data.code;
    }
    page.$error({ code: status });
  },

  // getToken: (code, isRefresh = false) => {
  //   let headers = {
  //     Authorization:
  //       "Basic " +
  //       Base64.encode([$CONFIG.clientId, $CONFIG.clientSecret].join(":")),
  //     "Content-Type": "application/x-www-form-urlencoded",
  //   };
  //   let formData = {
  //     scope: "all",
  //     grant_type: "authorization_code",
  //     client_id: $CONFIG.clientId,
  //     client_secret: $CONFIG.clientSecret,
  //     redirect_uri: "//" + document.location.host + "/app.html",
  //     code: code,
  //   };
  //   if (isRefresh) {
  //     headers = {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     formData = {
  //       grant_type: "refresh_token",
  //       client_id: $CONFIG.clientId,
  //       client_secret: $CONFIG.clientSecret,
  //       refresh_token: $STORE.state.token.refresh_token,
  //       code: code,
  //     };
  //   }

  //   let p = new Promise(function (resolve, reject) {
  //     apis
  //       .post(
  //         $CONFIG.ucApiRoot + "/oauth/token",
  //         qs.stringify(formData),
  //         null,
  //         headers
  //       )
  //       .then((res) => {
  //         $STORE.dispatch("setObj", {
  //           token: res,
  //         });
  //         resolve(res);
  //       })
  //       .catch((exc) => {
  //         reject(exc);
  //       });
  //   });
  //   return p;
  // },
  // refreshToken: async (response) => {
  //   let requestsQueue = [];
  //   let isRefreshToken = false;
  //   let config = response.config;
  //   if (!isRefreshToken) {
  //     isRefreshToken = true;
  //     let code =
  //       $STORE.state.personal.companyid + ":" + $STORE.state.personal.usercode;
  //     // 此处await有2个函数，因为要等待执行结果返回，不可随意更改
  //     let token = await apis.getToken(Base64.encode(code), true);
  //     // 权限绑定到指定用户
  //     await apis.post(
  //       $CONFIG.apiRoot +
  //         "/user/info/" +
  //         $STORE.state.personal.companyid +
  //         "/" +
  //         $STORE.state.personal.usercode
  //     );
  //     requestsQueue.forEach((callback) => callback(token));
  //     requestsQueue = [];
  //     return ins.request(config);
  //   } else {
  //     // 当前已经有请求在刷新token，将本次请求加入请求队列
  //     return new Promise((resolve) => {
  //       requestsQueue.push((token) => {
  //         config.headers.Authorization =
  //           _.upperFirst(token.token_type) + " " + token.access_token;
  //         resolve(ins.request(config));
  //       });
  //     });
  //   }
  // },
  // expiredToken: (response) => {
  //   if (!isMessageVisible) {
  //     Message({
  //       showClose: true,
  //       center: true,
  //       message: response.data.msg,
  //       type: "error",
  //       onClose: () => {
  //         isMessageVisible = true;
  //         apis.logout();
  //       },
  //     });
  //   }
  // },

  // logout: () => {
  //   ins.post($CONFIG.logouturl).then(() => {
  //     $STORE.dispatch("setObj", { loading: true });
  //     $STORE.dispatch("clear");
  //     location.href = $CONFIG.loginurl;
  //   });
  // },
  get: (url, params = {}, headers = {}) => {
    let p = new Promise((resolve, reject) => {
      ins
        .get(url, { params: params, headers: headers })
        .then((response) => {
          if (response) resolve(response.data);
        })
        .catch((exc) => {
          reject(exc);
        });
    });
    return p;
  },
  // post: (url, data, params = {}, headers = {}) => {
  //   let p = new Promise(function (resolve, reject) {
  //     ins
  //       .post(url, data, { params: params, headers: headers })
  //       .then((response) => {
  //         if (response) resolve(response.data);
  //       })
  //       .catch((exc) => {
  //         reject(exc);
  //       });
  //   });
  //   return p;
  // },
  put: (url, data, params = {}, headers = {}) => {
    let p = new Promise(function (resolve, reject) {
      ins
        .put(url, data, { params: params, headers: headers })
        .then((response) => {
          if (response) resolve(response.data);
        })
        .catch((exc) => {
          reject(exc);
        });
    });
    return p;
  },
  // del: (url, params = {}, headers = {}) => {
  //   let p = new Promise(function (resolve, reject) {
  //     ins
  //       .delete(url, { params: params, headers: headers })
  //       .then((response) => {
  //         if (response) resolve(response.data);
  //       })
  //       .catch((exc) => {
  //         reject(exc);
  //       });
  //   });
  //   return p;
  // },
  // upload: (url, data, params = {}, headers = {}) => {
  //   let p = new Promise(function (resolve, reject) {
  //     ins
  //       .post(url, data, {
  //         params: params,
  //         headers: {
  //           "Content-Type": "multipart/form-data;charset=UTF-8",
  //           Accept: "*/*",
  //           ...headers,
  //         },
  //       })
  //       .then((response) => {
  //         if (response) resolve(response.data);
  //       })
  //       .catch((exc) => {
  //         reject(exc);
  //       });
  //   });
  //   return p;
  // },
  getData: (uri, params = {}, headers = {}, isback = 0) => {
    let url = "/api/anon" + uri;
    let p = new Promise((resolve, reject) => {
      apis
        .get(url, params, headers)
        .then((res) => {
          if (res.code === 0) {
            if (isback) {
              apis.callSuccess(res);
            }
          }
          resolve(res);
        })
        .catch((exc) => {
          reject(exc);
        });
    });
    return p;
  },
  // postData: (uri, data, params = {}, headers = {}, isback = 1) => {
  //   let url = "/anon" + uri;
  //   let p = new Promise(function (resolve, reject) {
  //     apis
  //       .post(url, data, params, headers)
  //       .then((res) => {
  //         if (res.code === 0) {
  //           if (isback) {
  //             apis.callSuccess(res);
  //           }
  //         }
  //         resolve(res);
  //       })
  //       .catch((exc) => {
  //         reject(exc);
  //       });
  //   });
  //   return p;
  // },
  putData: (uri, data, params = {}, headers = {}, isback = 1) => {
    let url = "/api/anon" + uri;
    let p = new Promise(function (resolve, reject) {
      apis
        .put(url, data, params, headers)
        .then((res) => {
          if (res.code === 0) {
            if (isback) {
              apis.callSuccess(res);
            }
          }
          resolve(res);
        })
        .catch((exc) => {
          reject(exc);
        });
    });
    return p;
  },
  // delData: (uri, params = {}, headers = {}, isback = 1) => {
  //   let url = "/anon" + uri;
  //   let p = new Promise(function (resolve, reject) {
  //     apis
  //       .del(url, params, headers)
  //       .then((res) => {
  //         if (res.code === 0) {
  //           if (isback) {
  //             apis.callSuccess(res);
  //           }
  //         }
  //         resolve(res);
  //       })
  //       .catch((exc) => {
  //         reject(exc);
  //       });
  //   });
  //   return p;
  // },
  // uploadFile: (file, type = "image", params = {}, headers = {}, isback = 0) => {
  //   let url = $CONFIG.uploadAction + "/" + type;
  //   let p = new Promise(function (resolve, reject) {
  //     let data = new FormData();
  //     data.append("file", file.blob(), file.filename());
  //     apis
  //       .upload(url, data, params, headers)
  //       .then((res) => {
  //         if (res.code === 0) {
  //           if (isback) {
  //             apis.callSuccess(res);
  //           }
  //         }
  //         resolve(res);
  //       })
  //       .catch((exc) => {
  //         reject(exc);
  //       });
  //   });
  //   return p;
  // },
  // getUCData: (uri, params = {}, headers = {}, isback = 0) => {
  //   let url = $CONFIG.userUcApiRoot + uri;
  //   let p = new Promise(function (resolve, reject) {
  //     apis
  //       .get(url, params, headers)
  //       .then((res) => {
  //         if (res.code === 0) {
  //           if (isback) {
  //             apis.callSuccess(res);
  //           }
  //         }
  //         resolve(res);
  //       })
  //       .catch((exc) => {
  //         reject(exc);
  //       });
  //   });
  //   return p;
  // },
  // postUCData: (uri, data, params = {}, headers = {}, isback = 1) => {
  //   let url = $CONFIG.userUcApiRoot + uri;
  //   let p = new Promise(function (resolve, reject) {
  //     apis
  //       .post(url, data, params, headers)
  //       .then((res) => {
  //         if (res.code === 0) {
  //           if (isback) {
  //             apis.callSuccess(res);
  //           }
  //         }
  //         resolve(res);
  //       })
  //       .catch((exc) => {
  //         reject(exc);
  //       });
  //   });
  //   return p;
  // },
};

export default apis;
