import _ from "lodash";
const util = {
  install: (Vue) => {},

  // 平铺转树
  listToTree: (data, root = 0, key = "id", pKey = "pid") => {
    const gData = _.groupBy(data, pKey);
    return _.map(gData[root] || [], (item) => ({
      ...item,
      children: util.listToTree(data, item[key]),
    }));
  },

  // 树转平铺
  treeToList: (data) => {
    return _.flatMapDeep(data, (node) => [
      { ...node, children: [] },
      util.treeToList(node.children),
    ]);
  },

  // 树转叶子平铺
  treeToLeaf: (data, tKey, path = "") => {
    return _.flatMap(_.cloneDeep(data), (node) => {
      node[tKey] = path ? `${path} / ${node[tKey]}` : node[tKey];
      if (_.isEmpty(node.children)) {
        return node;
      } else {
        return util.treeToLeaf(node.children, tKey, node[tKey]);
      }
    });
  },

  // 获取指定节点
  getTreeNode: (data, val, key = "id") => {
    return _.find(util.treeToList(data), { [key]: val }) || null;
  },

  // 获取指定节点路径（通过节点值）
  getTreeNodePath: (data, val, key = "id", path = []) => {
    let result = [];
    if (!data) return [];
    _.forEach(data, (node) => {
      const currentPath = [...path, node];
      if (node[key] === val) {
        result = currentPath;
        return false;
      }
      if (node.children) {
        const child = util.getTreeNodePath(
          node.children,
          val,
          key,
          currentPath
        );
        if (child.length > 0) {
          result = child;
          return false;
        }
      }
    });
    return result;
  },

  // 对树符合条件的节点值用obj覆盖，增加属性，用于多级选框中禁止选中项
  mergeNodeProperty: (data, val = null, key, obj) => {
    if (!data) return false;
    data = _.map(data, (node) => {
      if (node[key] === val) {
        node = _.assign({}, node, obj);
      }
      if (node.children) {
        util.mergeNodeProperty(node.children, val, key, obj);
      }
      return node;
    });
    return data;
  },

  // 对树节点值克隆属性
  cloneNodeProperty: (data, key, newKey) => {
    if (!data) return false;
    data = _.map(data, (node) => {
      if (node.children) {
        util.cloneNodeProperty(node.children, key, newKey);
      }
      node[newKey] = node[key];
      return node;
    });
    return data;
  },

  // 对树符合条件的节点进行删除
  removeTreeNode: (data, val, key) => {
    if (!data) return false;
    data = data.map((node, index) => {
      if (node[key] === val) {
        data.splice(index, 1);
      }
      if (node.children) {
        util.removeTreeNode(node.children, val, key);
        if (node.children.length === 0) {
          node.children = null;
        }
      }
      return node;
    });
    return data;
  },

  // 金额小写转大写
  capital: (amount) => {
    let strOutput = "";
    let strUnit = "仟佰拾亿仟佰拾万仟佰拾元角分";
    amount += "00";
    let intPos = amount.indexOf(".");
    if (intPos >= 0)
      amount = amount.substring(0, intPos) + amount.substr(intPos + 1, 2);
    strUnit = strUnit.substr(strUnit.length - amount.length);
    for (let i = 0; i < amount.length; i++)
      strOutput +=
        "零壹贰叁肆伍陆柒捌玖".substr(amount.substr(i, 1), 1) +
        strUnit.substr(i, 1);
    return strOutput
      .replace(/零角零分$/, "整")
      .replace(/零[仟佰拾]/g, "零")
      .replace(/零{2,}/g, "零")
      .replace(/零([亿|万])/g, "$1")
      .replace(/零+元/, "元")
      .replace(/亿零{0,3}万/, "亿")
      .replace(/^元/, "零元");
  },

  // BASE64转二进制文件
  dataURLtoBlob: (dataurl) => {
    if (!dataurl) return;
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  },

  // 二进制文件转BASE64
  blobToDataURL: (blob, callback) => {
    if (!blob) return;
    let a = new FileReader();
    a.onload((e) => {
      callback(e.target.result);
    });
    a.readAsDataURL(blob);
  },

  // BASE64转文件
  dataURLtoFile: (dataurl, filename) => {
    if (!dataurl) return;
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  },

  // 动态加载CSS文件
  loadCss: (url, key = "") => {
    let style = document.getElementById("css" + key);
    if (style) {
      style.href = url;
    } else {
      style = document.createElement("link");
      url = url + (url.indexOf("?") > 0 ? "&" : "?") + _.random(0);
      style.id = "css" + key;
      style.rel = "stylesheet";
      style.lang = "text/css";
      style.href = url;
      document.head.appendChild(style);
    }
  },

  // 动态加载CSS
  loadStyle: (str, key = "") => {
    let style = document.getElementById("style" + key);
    if (style) {
      style.innerHTML = str;
    } else {
      style = document.createElement("style");
      style.id = "style" + key;
      style.type = "text/css";
      style.innerHTML = str;
      document.head.appendChild(style);
    }
  },

  // 动态加载JS文件
  loadJs: (url, key = "") => {
    let script = document.getElementById("js" + key);
    if (script) {
      script.src = url;
    } else {
      let script = document.createElement("script");
      url = url + (url.indexOf("?") > 0 ? "&" : "?") + _.random(0);
      script.id = "js" + key;
      script.type = "text/javascript";
      script.src = url;
      document.body.appendChild(script);
    }
  },

  // 动态加载JS
  loadScript: (str, key = "") => {
    let script = document.getElementById("script" + key);
    if (script) {
      script.innerHTML = str;
    } else {
      script = document.createElement("script");
      script.id = "script" + key;
      script.type = "text/javascript";
      script.innerHTML = str;
      document.body.appendChild(script);
    }
  },
};
export default util;
