<template>
  <swiper
    ref="banner"
    class="common-banner__container"
    :options="bannerSwiperOptions"
  >
    <swiper-slide
      v-for="(item, index) in bannerItems"
      :key="index"
      :class="menu.categoryWebbannerdataArray[0].listmode"
      :style="
        'background-image: url(' +
        item.contentImgurlArray[0] +
        '?imageView2/2/w/800/h/600);'
      "
    >
      <div class="txt">
        <h1>{{ item.contentTitle }}</h1>
        <div>{{ item.contentAbstract }}</div>
      </div>
    </swiper-slide>
    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
  </swiper>
</template>

<script>
export default {
  name: "TtCommonBanner",
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    bannerItems: [],
  }),
  computed: {
    bannerSwiper() {
      return this.$refs.pageSwiper.$swiper;
    },
    bannerSwiperOptions() {
      return {
        nested: true,
        grabCursor: true,
        autoplay: true,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      };
    },
  },
  mounted() {
    let that = this;
    that.getBannerItems();
  },
  methods: {
    getBannerItems() {
      let that = this;
      that.$API
        .getData(
          "/content/common/list/" +
            that.menu.categoryWebbannerdataArray[0].size,
          { categoryPid: that.menu.categoryWebbannerdataArray[0].id }
        )
        .then((res) => {
          if (res.code === 0) {
            that.bannerItems = res.data;
          }
        });
    },
  },
};
</script>
