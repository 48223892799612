<template>
  <div ref="info" class="category-info__container">
    <div v-if="item" class="category-info">
      <h1 v-if="visible.includes('title')" class="text-center">
        {{ item.categoryTitle }}
      </h1>
      <div v-if="visible.includes('image')" class="text-center box-margin-v">
        <el-image
          v-for="(url, index) in item.categoryImgurlArray"
          :key="index"
          :src="url"
        >
          <i slot="error" class="icon icon-nopic"> </i>
        </el-image>
      </div>
      <div
        v-if="
          visible.includes('abstract') &&
          item.categoryAbstract &&
          item.categoryAbstract.length != 0
        "
        class="bg-gray box-padding box-round"
      >
        {{ item.categoryAbstract }}
      </div>
      <div
        v-if="visible.includes('remark')"
        class="content"
        v-html="item.remark"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TtCategoryInfo",
  props: {
    cid: {
      type: String,
    },
    visible: {
      type: Array,
      default: () => ["title", "datetime", "image", "abstract", "remark"],
    },
  },
  computed: {
    ...mapState({
      categoryItems: (state) => state.categoryItems,
    }),
    item() {
      return this.$UTIL.getTreeNode(
        this.categoryItems,
        this.cid || this.$route.params.cid
      );
    },
  },
};
</script>
