<template>
  <div v-if="menu" class="page-wrapper">
    <div class="page-banner">
      <tt-common-banner :menu="menu"></tt-common-banner>
    </div>
    <div class="page-main">
      <el-container :class="['page-main__container', menu.categoryLayoutmode]">
        <keep-alive>
          <tt-category-item :menu="menu"></tt-category-item>
        </keep-alive>
        <tt-content-info :menu="menu"> </tt-content-info>
      </el-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info",
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
};
</script>
