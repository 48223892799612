<template>
  <div ref="list" class="content-list__container">
    <div v-if="!!item" class="content-info__detail">
      <div v-if="item" class="info-detail">
        <h1 v-if="visible.includes('title')" class="title">
          {{ item.contentTitle }}
        </h1>
        <div v-if="visible.includes('abstract')" class="abstract">
          {{ item.contentAbstract }}
        </div>
        <div
          v-if="visible.includes('detail')"
          class="content"
          v-html="item.contentDetail"
        ></div>
      </div>
    </div>
    <el-row v-else :gutter="20" :class="[listmode, 'content-list_detail']">
      <el-skeleton :loading="loading" :count="6" :throttle="300" animated>
        <el-col :sm="24" :md="12" slot="template">
          <div class="item-main">
            <div class="item-detail">
              <el-skeleton-item variant="image" class="img" />
              <div class="txt">
                <h3><el-skeleton-item variant="h3" /></h3>
                <el-skeleton-item variant="text" />
                <el-skeleton-item variant="text" />
                <el-skeleton-item variant="text" />
              </div>
            </div>
          </div>
        </el-col>
      </el-skeleton>
      <el-col v-for="(item, index) in items" :key="index" :sm="24" :md="12">
        <div class="item-main cursor-pointer">
          <div class="item-detail" v-scroll-reveal="reveal">
            <el-image
              v-if="visible.includes('image')"
              class="img"
              fit="cover"
              :src="item.contentImgurlArray[0] + '?imageView2/2/w/800/h/600'"
              :preview-src-list="item.contentImgurlArray"
            >
              <i slot="error" class="icon icon-nopic"> </i>
            </el-image>
            <div class="txt" @click="handleClick(item)">
              <h3 v-if="visible.includes('title')" class="text-ellipsis">
                {{ item.contentTitle }}
              </h3>
              <div
                v-if="visible.includes('abstract')"
                :class="['desc', 'text-ellipsis-3']"
              >
                {{ item.contentAbstract }}
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "TtContentList",
  props: {
    cid: {
      type: String,
      require: true,
    },
    size: {
      type: Number,
      required: false,
      default: 3,
    },
    isIndex: {
      type: Boolean,
      required: false,
      default: false,
    },
    listmode: {
      type: String,
      required: false,
      default: "img-l",
    },
    reveal: {
      type: Object,
      required: false,
      default: () => ({
        easing: "ease-in-out",
        scale: 0.8,
      }),
    },
    visible: {
      type: Array,
      default: () => ["title", "image", "abstract"],
    },
  },
  data: () => ({
    loading: true,
    items: null,
    item: null,
  }),
  watch: {
    $route(to, from) {
      let that = this;
      if (to.name == from.name) {
        that.getContentItems();
      }
    },
  },
  mounted() {
    let that = this;
    that.getContentItems();
  },
  methods: {
    getContentItems() {
      let that = this;
      let uri = "/content/common/list/" + that.size;
      let query = that.isIndex
        ? { categoryPid: that.cid, contentIsindex: 1 }
        : { categoryPid: that.cid };
      that.$API.getData(uri, query).then((res) => {
        let items = res.data;
        if (items.length == 1 && that.size === 1) {
          that.item = items[0];
        } else {
          that.items = items;
        }
        that.loading = false;
      });
    },
    handleClick(item) {
      let that = this;
      that.setObj({
        metaInfo: {
          cid: item.categoryId,
          title: item.contentTitle,
          keywords: item.contentKeywords,
          description: item.contentAbstract,
        },
      });
      that.$router.push({
        name: "info",
        params: { mid: that.$route.params.mid, id: item.id },
      });
    },
  },
};
</script>
