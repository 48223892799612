<template>
  <div v-if="menu" class="page-wrapper">
    <div class="page-banner">
      <tt-common-banner :menu="menu"></tt-common-banner>
    </div>
    <div class="page-main">
      <el-container :class="['page-main__container', menu.categoryLayoutmode]">
        <keep-alive>
          <tt-category-item :menu="menu"></tt-category-item>
        </keep-alive>
        <tt-content-item :menu="menu"> </tt-content-item>
      </el-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Item",
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.page-main {
  overflow: visible;
  padding: 20px 0;
}
.page-main__container {
  margin-left: auto;
  margin-right: auto;
  width: $--container_main-width;
  max-width: $--container_main-maxwidth;
}
</style>
