<template>
  <el-container direction="vertical" :class="[$route.name]">
    <tt-header></tt-header>
    <ts-default v-if="baseInfo.indextpl === 'Default'"></ts-default>
    <tt-common-footer></tt-common-footer>
  </el-container>
</template>

<script>
import TsDefault from "./Default";
import { mapState } from "vuex";
export default {
  name: "TtIndex",
  components: {
    TsDefault,
  },
  computed: {
    ...mapState({
      baseInfo: (state) => state.baseInfo,
    }),
  },
};
</script>
