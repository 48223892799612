<template>
  <footer class="footer">
    <div class="footer-navbar">
      <el-row :gutter="100" class="footer-navbar__item">
        <el-col
          v-for="item in footItems"
          :key="item.id"
          :span="6"
          class="item__detail"
        >
          <h4 class="title line-height-2">{{ item.contentTitle }}</h4>
          <div
            :class="['list', baseInfo.footerdata[0].listmode]"
            v-html="item.contentDetail"
          ></div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-backtop><i class="el-icon-top"></i></el-backtop>
      <div class="text-center">{{ baseInfo.copyright }}</div>
      <div class="text-center text-small text-gray box-margin-t">
        <a target="_blank" href="https://beian.miit.gov.cn/">
          [ {{ baseInfo.recordno }} ]
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TtCommonFooter",
  data: () => ({
    footItems: [],
  }),
  computed: {
    ...mapState({
      baseInfo: (state) => state.baseInfo,
    }),
  },
  mounted() {
    let that = this;
    that.getFootItems();
  },
  methods: {
    getFootItems() {
      let that = this;
      that.$API
        .getData("/content/common/list/" + that.baseInfo.footerdata[0].size, {
          categoryPid: that.baseInfo.footerdata[0].id,
        })
        .then((res) => {
          if (res.code === 0) {
            that.footItems = res.data;
          }
        });
    },
  },
};
</script>
