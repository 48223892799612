<template>
  <el-main ref="info" class="content-info__container">
    <div class="content-info__detail">
      <el-skeleton :loading="loading" :throttle="300" animated>
        <div class="info-detail" slot="template">
          <h1 class="title"><el-skeleton-item variant="h1" /></h1>
          <div class="datetime">
            <el-skeleton-item variant="text" class="box-w-300" />
          </div>
          <div v-if="visible.includes('abstract')" class="abstract">
            <el-skeleton-item variant="text" />
            <el-skeleton-item variant="text" />
            <el-skeleton-item variant="text" class="box-w-200" />
          </div>
          <el-container v-if="visible.includes('image')" class="image">
            <el-main>
              <el-skeleton-item variant="image" class="el-image" />
            </el-main>
            <el-aside width="100px">
              <el-skeleton-item
                variant="image"
                class="el-image img-thumb rounded medium"
              />
              <el-skeleton-item
                variant="image"
                class="el-image img-thumb rounded medium"
              />
            </el-aside>
          </el-container>
          <el-skeleton-item variant="text" />
          <el-skeleton-item variant="text" />
          <el-skeleton-item variant="text" />
          <el-skeleton-item variant="text" />
          <el-skeleton-item variant="text" />
          <el-skeleton-item variant="text" class="box-w-200" />
        </div>
      </el-skeleton>

      <div v-if="!!item" class="info-detail">
        <h1 v-if="visible.includes('title')" class="title">
          {{ item.contentTitle }}
        </h1>
        <div v-if="visible.includes('datetime')" class="datetime">
          <label> 创建时间 </label>： {{ item.createtime }}
          <label> 点击量 </label>：{{ item.contentClicks }}
        </div>
        <div
          v-if="
            visible.includes('abstract') &&
            item.contentAbstract &&
            item.contentAbstract.length != 0
          "
          class="abstract"
        >
          {{ item.contentAbstract }}
        </div>
        <el-container v-if="visible.includes('image')" class="image">
          <el-main>
            <el-image
              :src="previewImgurl + '?imageView2/2/w/800/h/600'"
              :preview-src-list="[previewImgurl]"
              fit="cover"
            >
              <i slot="error" class="icon icon-nopic"> </i>
            </el-image>
          </el-main>
          <el-aside width="100px">
            <el-image
              v-for="(url, index) in item.contentImgurlArray"
              :key="index"
              :src="url + '?imageView2/2/w/800/h/600'"
              class="img-thumb rounded medium"
              @click="previewImgurl = url"
            >
              <i slot="error" class="icon icon-nopic"> </i>
            </el-image>
          </el-aside>
        </el-container>
        <div
          v-if="visible.includes('detail')"
          class="content"
          v-html="item.contentDetail"
        ></div>
      </div>
    </div>
  </el-main>
</template>

<script>
export default {
  name: "TtContentInfo",
  props: {
    id: {
      type: String,
    },
    visible: {
      type: Array,
      default: () => ["title", "datetime", "image", "abstract", "detail"],
    },
  },
  data: () => ({
    loading: true,
    previewImgurl: null,
    item: null,
  }),
  computed: {
    contentId() {
      return this.id || this.$route.params.id;
    },
  },
  watch: {
    $route(to, from) {
      let that = this;
      if (to.name == from.name) {
        that.loading = true;
        that.item = {};
        that.getContentInfo();
      }
    },
  },
  mounted() {
    let that = this;
    that.getContentInfo();
  },
  methods: {
    putContentInfoClick(id) {
      let that = this;
      that.$API.putData("/content/common/click/" + id, null, null, null, 0);
    },
    getContentInfo() {
      let that = this;
      let uri =
        "/content/common" +
        (that.contentId ? "/info/" + that.contentId : "/list/1");
      that.$API.getData(uri).then((res) => {
        if (res.code === 0) {
          that.item = that.contentId ? res.data : res.data[0];
          that.previewImgurl = that.item.contentImgurlArray[0];
          that.loading = false;
          that.putContentInfoClick(that.item.id);
        }
      });
    },
  },
};
</script>
