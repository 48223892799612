<template>
  <div v-if="visible" class="base-loading">
    {{ visible }}
    <div class="loading-main">
      <div class="loading-main__spinner">
        <div v-if="icon" :class="animation"><i :class="icon"> </i></div>
        <svg v-else viewBox="25 25 50 50" class="circular">
          <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
        </svg>
      </div>
      <div class="loading-main__label">{{ message.label }}</div>
      <div class="loading-main__text">{{ message.text }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    visible: true,
    animation: "animation-roating",
    icon: null,
    message: {
      label: "拼命加载中",
      text: "",
    },
  }),
  methods: {
    close() {
      this.visible = false;
    },
    refresh() {
      location.reload();
    },
  },
};
</script>
<style lang="scss">
.base-loading {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  z-index: 1000;
  .loading-main {
    flex: 1;
    margin: 0 auto;
    text-align: center;
  }
  .loading-main__label {
    color: #409eff;
    line-height: 40px;
    display: inline-block;
    &::after {
      content: "";
      animation: dot 2s infinite steps(3);
    }
  }
  .loading-main__spinner {
    margin: 0 auto;
    width: 40px;
    height: 40px;
    line-height: 40px;
    .circular {
      width: 100%;
      height: 100%;
    }
    .animation-roating {
      animation: animation-roating 3s linear infinite;
    }
    .path {
      animation: animation-rotate 1.5s ease-in-out infinite;
      stroke-dasharray: 90, 150;
      stroke-dashoffset: 0;
      stroke-width: 2;
      stroke: #409eff;
      stroke-linecap: round;
    }
  }
  .loading-main__text {
    color: #999;
  }
}

@keyframes animation-rotate {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
  }
}

@keyframes animation-roating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}
</style>
