import Vue from "vue";
import Vuex from "vuex";
// import i18n from '../common/lang'

// import { Locale } from 'vant'
// import enUS from 'vant/lib/locale/lang/en-US'
// import zhCN from 'vant/lib/locale/lang/zh-CN'
// import vi from 'vant/lib/locale/lang/vi'

Vue.use(Vuex);

const modules = {
  state: {
    routerName: sessionStorage.getItem("routerName"),
    lang: sessionStorage.getItem("lang"),
    metaInfo: JSON.parse(sessionStorage.getItem("metaInfo") || "null"),
    categoryItems: JSON.parse(
      sessionStorage.getItem("categoryItems") || "null"
    ),
    baseInfo: JSON.parse(sessionStorage.getItem("baseInfo") || "null"),
  },
  actions: {
    init({ commit }) {
      commit("getSession", [
        "routerName",
        "lang",
        "siteTitle",
        "categoryItems",
        "baseInfo",
      ]);
    },
    setObj({ commit }, obj) {
      commit("setObj", obj);
      commit("setSession", Object.keys(obj));
    },
  },
  mutations: {
    setObj(state, obj) {
      for (const key in obj) {
        state[key] = obj[key];
      }
    },
    getSession(state, keys) {
      keys.forEach((key) => {
        if (sessionStorage.getItem(key)) {
          state[key] = sessionStorage.getItem(key);
        } else if (key === "lang") {
          let lang = (
            navigator.language || navigator.browserLanguage
          ).toLowerCase();
          if (["zh-tw", "zh-hans-cn"].includes(lang)) {
            state.lang = "zh-cn";
            sessionStorage.setItem("lang", lang);
          }
        }
      });
    },
    setSession(state, keys) {
      keys.forEach((key) => {
        if (state[key] && state[key] != null) {
          if (typeof state[key] === "object") {
            sessionStorage.setItem(key, JSON.stringify(state[key]));
          } else {
            sessionStorage.setItem(key, state[key]);
          }
        } else {
          sessionStorage.removeItem(key);
        }
      });
    },
  },
};

export default new Vuex.Store(modules);
