import Vue from "vue";
import App from "./App";
import router from "../router";
import store from "../store";

import _ from "lodash";
import VueScrollReveal from "vue-scroll-reveal";
import VueSocialShare from "vue-social-share";
import VueAwesomeSwiper from "vue-awesome-swiper";

// import i18n from "../../common/lang";
import api from "../common/api";
import util from "../common/util";
import components from "../components";
import templates from "../templates";
import {
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Row,
  Col,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Card,
  Carousel,
  CarouselItem,
  Image,
  Tag,
  Button,
  Divider,
  Skeleton,
  SkeletonItem,
  Backtop,
} from "element-ui";

Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Row);
Vue.use(Col);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Card);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Image);
Vue.use(Tag);
Vue.use(Button);
Vue.use(Divider);
Vue.use(Skeleton);
Vue.use(SkeletonItem);
Vue.use(Backtop);

Vue.use(VueScrollReveal);
Vue.use(VueSocialShare);
Vue.use(VueAwesomeSwiper /* { default options with global component } */);

Vue.use(api, { store });
Vue.use(util);
Vue.use(components);
Vue.use(templates);

Vue.prototype.$API = api;
Vue.prototype.$UTIL = util;
Vue.prototype._ = _;
Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if (!store.state.baseInfo) {
    let timer = setInterval(() => {
      if (!!store.state.baseInfo) {
        clearInterval(timer);
        next();
      }
    }, 500);
  } else {
    next();
  }
});

router.afterEach((to) => {
  if (!!store.state.baseInfo) {
    let title = [store.state.baseInfo.title];
    let keywords = store.state.baseInfo.keywords;
    let description = store.state.baseInfo.description;
    if (store.state.metaInfo) {
      let node = util.getTreeNode(
        store.state.categoryItems,
        store.state.metaInfo.cid
      );
      let titleArr = util
        .getTreeNodePath(store.state.categoryItems, store.state.metaInfo.cid)
        .map((item) => item.categoryTitle);
      let keywordsArr = [node.categoryKeywords];
      let descriptionArr = [node.categoryAbstract];

      if (store.state.metaInfo.title) titleArr.push(store.state.metaInfo.title);
      if (store.state.metaInfo.keywords)
        keywordsArr.push(store.state.metaInfo.keywords);
      if (store.state.metaInfo.description)
        descriptionArr.push(store.state.metaInfo.description);

      title = titleArr.reverse().join("-") + " - " + title;
      keywords = keywordsArr.reverse().join("-") + " - " + keywords;
      description = descriptionArr.reverse().join("-") + " - " + description;
    }
    document.title = title;
    document.querySelector("meta[name=keywords]").content = keywords;
    document.querySelector("meta[name=description]").content = description;
  }
});

new Vue({
  render: (h) => h(App),
  store,
  router,
  // i18n: i18n,
}).$mount("#app");
