<template>
  <el-main v-if="menu" ref="page" class="content-item__container">
    <div v-if="!!item" class="content-info__detail">
      <div class="info-detail">
        <h1 v-if="visible.includes('title')" class="title">
          {{ item.contentTitle }}
        </h1>
        <div v-if="visible.includes('datetime')" class="datetime">
          <label> 创建时间 </label>： {{ item.createtime }}
          <label> 点击量 </label>：{{ item.contentClicks }}
        </div>
        <div
          v-if="
            visible.includes('abstract') &&
            item.contentAbstract &&
            item.contentAbstract.length != 0
          "
          class="abstract"
        >
          {{ item.contentAbstract }}
        </div>
        <el-container v-if="visible.includes('image')" class="image">
          <el-main>
            <el-image
              :src="previewImgurl + '?imageView2/2/w/800/h/600'"
              :preview-src-list="[previewImgurl]"
              fit="cover"
            >
              <i slot="error" class="icon icon-nopic"> </i>
            </el-image>
          </el-main>
          <el-aside width="100px">
            <el-image
              v-for="(url, index) in item.contentImgurlArray"
              :key="index"
              :src="url + '?imageView2/2/w/800/h/600'"
              class="img-thumb rounded medium"
              @click="previewImgurl = url"
            >
              <i slot="error" class="icon icon-nopic"> </i>
            </el-image>
          </el-aside>
        </el-container>
        <div
          v-if="visible.includes('detail')"
          class="content"
          v-html="item.contentDetail"
        ></div>
      </div>
    </div>
    <el-row
      v-else
      :gutter="20"
      :class="[
        menu.categoryFillmode,
        menu.categoryListmode,
        'content-item__detail',
      ]"
    >
      <el-skeleton :loading="loading" :count="6" :throttle="300" animated>
        <el-col :sm="24" :md="12" :lg="8" slot="template">
          <div class="item-main">
            <div class="item-detail">
              <el-skeleton-item variant="image" class="img" />
              <div class="txt">
                <h3><el-skeleton-item variant="h3" /></h3>
                <el-skeleton-item variant="text" />
                <el-skeleton-item variant="text" />
                <el-skeleton-item variant="text" />
              </div>
            </div>
          </div>
        </el-col>
      </el-skeleton>
      <el-col
        v-for="(item, index) in items"
        :key="index"
        :sm="24"
        :md="12"
        :lg="8"
      >
        <div class="item-main cursor-pointer">
          <div class="item-detail" v-scroll-reveal="reveal">
            <el-image
              v-if="visible.includes('image')"
              class="img"
              fit="cover"
              :src="item.contentImgurlArray[0] + '?imageView2/2/w/800/h/600'"
              :preview-src-list="item.contentImgurlArray"
            >
              <i slot="error" class="icon icon-nopic"> </i>
            </el-image>
            <div
              v-if="visible.includes('title') && visible.includes('abstract')"
              class="txt"
              @click="handleClick(item)"
            >
              <h3 v-if="visible.includes('title')" class="text-ellipsis">
                {{ item.contentTitle }}
                <span
                  v-if="
                    visible.includes('subtitle') &&
                    item.contentSubtitle.length > 0
                  "
                  class="text-small"
                >
                  －－ {{ item.contentSubtitle }}
                </span>
              </h3>
              <div
                v-if="visible.includes('abstract')"
                :class="['desc', 'text-ellipsis-3']"
              >
                {{ item.contentAbstract }}
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-divider>没有更多了</el-divider>
  </el-main>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "TtContentItem",
  props: {
    cid: {
      type: String,
    },
    menu: {
      type: Object,
      required: true,
    },
    itemType: {
      type: String,
      default: "page",
    },
    reveal: {
      type: Object,
      required: false,
      default: () => ({
        easing: "ease-in-out",
        scale: 0.8,
      }),
    },
    visible: {
      type: Array,
      default: () => ["title", "subtitle", "image", "abstract", "detail"],
    },
  },
  data: () => ({
    loading: true,
    items: [],
    item: null,
  }),
  computed: {
    ...mapState({
      categoryItems: (state) => state.categoryItems,
    }),
    categoryId() {
      return this.cid || this.$route.query.cid || this.menu.id;
    },
  },
  watch: {
    $route(to, from) {
      let that = this;
      if (to.name == from.name) {
        that.loading = true;
        that.items = null;
        that.item = null;
        that.getContentItems();
      }
    },
  },
  mounted() {
    let that = this;
    that.getContentItems();
  },
  methods: {
    ...mapActions(["setObj"]),
    getContentItems() {
      let that = this;
      let uri = "/content/common/page";
      let query = {
        categoryPid: that.categoryId,
      };
      that.$API.getData(uri, query).then((res) => {
        if (res.code === 0) {
          let items = res.data;
          if ((!that.menu.children && items.length) || items.length == 1) {
            that.item = items[0];
            that.previewImgurl = that.item.contentImgurlArray[0];
          } else {
            that.items = items;
          }
          that.loading = false;
        }
      });
    },
    handleClick(item) {
      let that = this;
      that.setObj({
        metaInfo: {
          cid: item.categoryId,
          title: item.contentTitle,
          keywords: item.contentKeywords,
          description: item.contentAbstract,
        },
      });
      that.$router.push({
        name: "info",
        params: { mid: that.$route.params.mid, id: item.id },
      });
    },
  },
};
</script>
