import TtIndex from "./index/Index";
import TtMain from "./main/Index";
import TtItem from "./item/Index";
import TtInfo from "./info/Index";

import TtHeader from "./common/Header";

const components = [TtIndex, TtMain, TtItem, TtInfo, TtHeader];

if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}

const install = function (Vue, opts = {}) {
  if (opts.scss) {
    require(`./assets/css/${opts.scss}.scss`);
  }
  components.forEach((component) => {
    Vue.component(component.name, component);
  });
};

export { install, TtIndex, TtMain, TtItem, TtInfo, TtHeader };
