<template>
  <el-aside ref="item" class="category-item__container">
    <ul v-if="menu != null" class="category-item__detail item-detail">
      <h2
        v-if="visible.includes('title')"
        class="cursor-pointer"
        :class="!$route.params.mid ? 'active' : ''"
        @click="$router.push({ name: 'item', params: $route.params })"
      >
        {{ menu.categoryTitle }}
      </h2>
      <div v-if="visible.includes('abstract')">
        {{ menu.categoryAbstract }}
      </div>
      <template v-if="(menu.children || []).length > 0">
        <li
          v-for="item in menu.children"
          :class="[
            'cursor-pointer',
            $route.query.cid == item.id ? 'active' : '',
          ]"
          @click="handleCategoryClick(item)"
        >
          {{ item.categoryTitle }}
        </li>
      </template>
      <template v-else>
        <li
          v-for="item in items"
          :class="[
            'cursor-pointer',
            $route.query.id == item.id ? 'active' : '',
          ]"
          @click="handleContentClick(item)"
        >
          {{ item.contentTitle }}
        </li>
      </template>
    </ul>
  </el-aside>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "TtCategoryItem",
  props: {
    menu: {
      type: Object,
      required: true,
    },
    visible: {
      type: Array,
      default: () => ["title", "abstract"],
    },
  },
  data: () => ({
    items: null,
  }),
  watch: {
    $route(to, from) {
      let that = this;
      if (to.name == from.name) {
        that.getContentItems();
      }
    },
  },
  mounted() {
    let that = this;
    that.getContentItems();
  },
  methods: {
    ...mapActions(["setObj"]),
    getContentItems() {
      let that = this;
      if (that.menu.id === "1722791922528944128") {
        that.$API
          .getData("/content/common/list/10", { categoryPid: that.menu.id })
          .then((res) => {
            if (res.code === 0) {
              that.items = res.data;
            }
          });
      }
    },
    handleCategoryClick(item) {
      let that = this;
      that.setObj({
        metaInfo: {
          cid: item.id,
        },
      });
      that.$router.push({
        name: "item",
        params: that.$route.params,
        query: { cid: item.id },
      });
    },
    handleContentClick(item) {
      let that = this;
      that.setObj({
        metaInfo: {
          cid: item.categoryId,
          title: item.contentTitle,
          keywords: item.contentKeywords,
          description: item.contentAbstract,
        },
      });
      that.$router.push({
        name: "info",
        params: { mid: that.$route.params.mid, id: item.id },
      });
    },
  },
};
</script>
