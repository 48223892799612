var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-aside',{ref:"item",staticClass:"category-item__container"},[(_vm.menu != null)?_c('ul',{staticClass:"category-item__detail item-detail"},[(_vm.visible.includes('title'))?_c('h2',{staticClass:"cursor-pointer",class:!_vm.$route.params.mid ? 'active' : '',on:{"click":function($event){return _vm.$router.push({ name: 'item', params: _vm.$route.params })}}},[_vm._v(" "+_vm._s(_vm.menu.categoryTitle)+" ")]):_vm._e(),(_vm.visible.includes('abstract'))?_c('div',[_vm._v(" "+_vm._s(_vm.menu.categoryAbstract)+" ")]):_vm._e(),((_vm.menu.children || []).length > 0)?_vm._l((_vm.menu.children),function(item){return _c('li',{class:[
          'cursor-pointer',
          _vm.$route.query.cid == item.id ? 'active' : '',
        ],on:{"click":function($event){return _vm.handleCategoryClick(item)}}},[_vm._v(" "+_vm._s(item.categoryTitle)+" ")])}):_vm._l((_vm.items),function(item){return _c('li',{class:[
          'cursor-pointer',
          _vm.$route.query.id == item.id ? 'active' : '',
        ],on:{"click":function($event){return _vm.handleContentClick(item)}}},[_vm._v(" "+_vm._s(item.contentTitle)+" ")])})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }