const dict = {
  ERROR: [
    {
      label: "未知",
      value: 0,
      text: "未知请示异常。",
    },
    {
      label: "继续",
      value: 100,
      text: "请求者应当继续提出请求。服务器返回此代码表示已收到请求的第一部分，正在等待其余部分。",
    },
    {
      label: "切换协议",
      value: 101,
      text: "请求者已要求服务器切换协议，服务器已确认并准备切换。",
    },
    {
      label: "已创建",
      value: 201,
      text: "请求成功并且服务器创建了新的资源。",
    },
    { label: "已接受", value: 202, text: "服务器已接受请求，但尚未处理。" },
    {
      label: "非授权信息",
      value: 203,
      text: "服务器已成功处理了请求，但返回的信息可能来自另一来源。",
    },
    {
      label: "无内容",
      value: 204,
      text: "服务器成功处理了请求，但没有返回任何内容。",
    },
    {
      label: "重置内容",
      value: 205,
      text: "服务器成功处理了请求，但没有返回任何内容。",
    },
    { label: "部分内容", value: 206, text: "服务器成功处理了部分 GET 请求。" },
    {
      label: "多种选择",
      value: 300,
      text: "针对请求，服务器可执行多种操作。服务器可根据请求者 (user agent) 选择一项操作，或提供操作列表供请求者选择。",
    },
    {
      label:
        "请求的地址已永久移动到新位置。服务器返回此响应（对 GET 或 HEAD 请求的响应",
      value: 301,
      text: "时，会自动将请求者转到新位置。",
    },
    {
      label: "临时移动",
      value: 302,
      text: "服务器目前从不同位置的地址响应请求，但请求者应继续使用原有位置来进行以后的请求。",
    },
    {
      label: "查看其他位置",
      value: 303,
      text: "请求者应当对不同的位置使用单独的 GET 请求来检索响应时，服务器返回此代码。",
    },
    {
      label: "未修改",
      value: 304,
      text: "自从上次请求后，请求的地址未修改过。服务器返回此响应时，不会返回地址内容。",
    },
    {
      label: "使用代理",
      value: 305,
      text: "请求者只能使用代理访问请求的地址。如果服务器返回此响应，还表示请求者应使用代理。",
    },
    {
      label: "临时重定向",
      value: 307,
      text: "服务器目前从不同位置的地址响应请求，但请求者应继续使用原有位置来进行以后的请求。",
    },
    { label: "错误请求", value: 400, text: "服务器不理解请求的语法。" },
    {
      label: "未授权",
      value: 401,
      text: "请求要求身份验证。 对于需要登录的地址，服务器可能返回此响应。",
    },
    { label: "禁止", value: 403, text: "服务器拒绝请求。" },
    { label: "找不到页面", value: 404, text: "服务器找不到请求的地址。" },
    { label: "方法禁用", value: 405, text: "禁用请求中指定的方法。" },
    {
      label: "不接受",
      value: 406,
      text: "无法使用请求的内容特性响应请求的地址。",
    },
    {
      label: "需要代理授权） 此状态代码与 401（未授权",
      value: 407,
      text: "类似，但指定请求者应当授权使用代理。",
    },
    { label: "请求超时", value: 408, text: "服务器等候请求时发生超时。" },
    {
      label: "冲突",
      value: 409,
      text: "服务器在完成请求时发生冲突。服务器必须在响应中包含有关冲突的信息。",
    },
    {
      label: "已删除",
      value: 410,
      text: "如果请求的资源已永久删除，服务器就会返回此响应。",
    },
    {
      label: "需要有效长度",
      value: 411,
      text: "服务器不接受不含有效内容长度标头字段的请求。",
    },
    {
      label: "未满足前提条件",
      value: 412,
      text: "服务器未满足请求者在请求中设置的其中一个前提条件。",
    },
    {
      label: "请求实体过大",
      value: 413,
      text: "服务器无法处理请求，因为请求实体过大，超出服务器的处理能力。",
    },
    {
      label: "请求的 URI 过长） 请求的 URI（通常为网址",
      value: 414,
      text: "过长，服务器无法处理。",
    },
    {
      label: "不支持的媒体类型",
      value: 415,
      text: "请求的格式不受请求页面的支持。",
    },
    {
      label: "请求范围不符合要求",
      value: 416,
      text: "如果页面无法提供请求的范围，则服务器会返回此状态代码。",
    },
    {
      label: "未满足期望值",
      value: 417,
      text: ' 服务器未满足"期望"请求标头字段的要求。',
    },
    {
      label: "服务器内部错误",
      value: 500,
      text: "服务器遇到错误，无法完成请求。",
    },
    {
      label: "尚未实施",
      value: 501,
      text: "服务器不具备完成请求的功能。例如，服务器无法识别请求方法时可能会返回此代码。",
    },
    {
      label: "错误网关",
      value: 502,
      text: "服务器作为网关或代理，从上游服务器收到无效响应。",
    },
    {
      label: "服务不可用） 服务器目前无法使用（由于超载或停机维护",
      value: 503,
      text: "。通常，这只是暂时状态。",
    },
    {
      label: "网关超时",
      value: 504,
      text: "服务器作为网关或代理，但是没有及时从上游服务器收到请求。",
    },
    {
      label: "HTTP 版本不受支持",
      value: 505,
      text: "服务器不支持请求中所用的 HTTP 协议版本。",
    },
    //服务器自定义错误
    {
      label: "部分成功",
      value: 1001,
      text: "部分成功",
    },
    {
      label: "潜在的性能问题",
      value: 1002,
      text: "潜在的性能问题",
    },
    {
      label: "请求参数不匹配",
      value: 4000,
      text: "请求参数不匹配",
    },
    {
      label: "Token无效或已过期",
      value: 4001,
      text: "Token无效或已过期",
    },
    {
      label: "无权执行该操作",
      value: 4003,
      text: "无权执行该操作",
    },
    {
      label: "请求资源不存在",
      value: 4004,
      text: "请求资源不存在",
    },
    {
      label: "数据校验不通过",
      value: 4005,
      text: "数据校验不通过",
    },
    {
      label: "操作执行失败",
      value: 4006,
      text: "操作执行失败",
    },
    {
      label: "请求连接超时",
      value: 4008,
      text: "请求连接超时",
    },
    {
      label: "认证不通过",
      value: 4009,
      text: "认证不通过",
    },
    {
      label: "无效的租户",
      value: 4011,
      text: "无效的租户",
    },
    {
      label: "无效的账号",
      value: 4012,
      text: "无效的账号",
    },
    {
      label: "系统异常",
      value: 5000,
      text: "系统异常",
    },
    {
      label: "服务不可用",
      value: 5003,
      text: "服务不可用",
    },
  ],
};

export default dict;
