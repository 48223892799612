<template>
  <router-view></router-view>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapState({
      baseInfo: (state) => state.baseInfo,
    }),
  },
  mounted() {
    let that = this;
    let host = window.location.host;
    if (that.isMobile()) {
      location.href = "//h5" + host.substring(host.indexOf("."));
      return;
    }
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
