<template>
  <div class="page-wrapper page-wrapper__home">
    <swiper
      v-if="baseInfo.indexdata.length > 0"
      ref="pageSwiper"
      :options="pageSwiperOptions"
      class="page"
    >
      <swiper-slide class="page-banner">
        <swiper
          ref="banner"
          class="common-banner__container"
          :options="bannerSwiperOptions"
        >
          <swiper-slide
            v-for="(item, index) in bannerItems"
            :key="index"
            :class="baseInfo.bannerdata[0].listmode"
            :style="
              'background-image: url(' +
              item.contentImgurlArray[0] +
              '?imageView2/2/w/800/h/600);'
            "
          >
            <div class="txt">
              <h1>{{ item.contentTitle }}</h1>
              <div>{{ item.contentAbstract }}</div>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </swiper-slide>

      <swiper-slide class="page-main">
        <div class="wrapper-bg wrapper-bg-l wrapper-bg1"></div>
        <div class="wrapper-bg wrapper-bg-r wrapper-bg2"></div>
        <el-row
          :gutter="50"
          type="flex"
          justify="center"
          align="middle"
          class="home-main__container"
        >
          <el-col :span="12">
            <tt-category-info :id="baseInfo.indexdata[0].id"></tt-category-info>
          </el-col>
          <el-col :span="12">
            <el-col
              v-for="(item, index) in categoryItems1"
              :key="index"
              :span="12"
            >
              <div :class="['item-main', 'box-padding-20']">
                <i
                  :class="[item.categoryIcon, 'box-margin-r']"
                  :style="{ color: item.categoryColor }"
                >
                </i>
                {{ item.categoryTitle }}
              </div>
            </el-col>
          </el-col>
        </el-row>
      </swiper-slide>

      <swiper-slide class="page-main">
        <div class="wrapper-bg wrapper-bg-t wrapper-bg3"></div>
        <div class="wrapper-bg wrapper-bg-b wrapper-bg4"></div>
        <el-row
          :gutter="50"
          type="flex"
          justify="center"
          align="middle"
          class="home-main__container"
        >
          <el-col :span="16">
            <tt-content-list
              :isIndex="true"
              :reveal="{ opacity: 1 }"
              :cid="baseInfo.indexdata[1].id"
              :listmode="baseInfo.indexdata[1].listmode"
              :size="baseInfo.indexdata[1].size"
              :visible="['title', 'image']"
            >
            </tt-content-list>
          </el-col>
          <el-col :span="8">
            <tt-category-info :id="baseInfo.indexdata[1].id"></tt-category-info>
          </el-col>
        </el-row>
      </swiper-slide>

      <swiper-slide class="page-main">
        <div class="wrapper-bg wrapper-bg-l wrapper-bg5"></div>
        <div class="wrapper-bg wrapper-bg-r wrapper-bg6"></div>
        <el-row
          :gutter="50"
          type="flex"
          justify="center"
          align="middle"
          class="home-main__container"
        >
          <el-col :span="12">
            <tt-category-info :id="baseInfo.indexdata[2].id"></tt-category-info>
          </el-col>
          <el-col :span="12">
            <tt-content-list
              :isIndex="true"
              :reveal="{ opacity: 1 }"
              :cid="baseInfo.indexdata[2].id"
              :listmode="baseInfo.indexdata[2].listmode"
              :size="baseInfo.indexdata[2].size"
            >
            </tt-content-list>
          </el-col>
        </el-row>
      </swiper-slide>

      <swiper-slide class="page-main">
        <div class="wrapper-bg wrapper-bg-b wrapper-bg7"></div>
        <div class="wrapper-bg wrapper-bg-t wrapper-bg8"></div>
        <el-row
          :gutter="50"
          type="flex"
          justify="center"
          align="middle"
          class="home-main__container"
        >
          <el-col :span="12">
            <tt-category-info :id="baseInfo.indexdata[3].id"></tt-category-info>
          </el-col>
          <el-col :span="12">
            <tt-content-list
              :isIndex="true"
              :reveal="{ opacity: 1 }"
              :cid="baseInfo.indexdata[3].id"
              :listmode="baseInfo.indexdata[3].listmode"
              :size="baseInfo.indexdata[3].size"
            >
            </tt-content-list>
          </el-col>
        </el-row>
      </swiper-slide>

      <swiper-slide></swiper-slide>

      <div class="page-next" slot="button-next">
        <div>向下滑动</div>
        <i class="el-icon-arrow-down"></i>
      </div>
    </swiper>
    <transition name="el-fade-in"> </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Default",
  data: () => ({
    bannerItems: [],
  }),
  computed: {
    ...mapState({
      baseInfo: (state) => state.baseInfo,
      categoryItems: (state) => state.categoryItems,
    }),
    categoryItems1() {
      let category =
        this.categoryItems.find(
          (item) => item.id === this.baseInfo.indexdata[0].id
        ) || {};
      return category.children || [];
    },
    pageSwiper() {
      return this.$refs.pageSwiper.$swiper;
    },
    bannerSwiper() {
      return this.$refs.bannerSwiper.$swiper;
    },
    pageSwiperOptions() {
      return {
        // height: window.innerHeight,
        autoHeight: true,
        watchSlidesProgress: true,
        direction: "vertical", // 垂直切换选项
        mousewheel: {
          forceToAxis: true,
          // releaseOnEdges: true
        },
        parallax: true,
        navigation: {
          nextEl: ".page-next",
        },
        // scrollbar: {
        //   el: '.swiper-scrollbar'
        // },
        hashNavigation: {
          watchState: true,
        },
        on: {
          reachEnd: () => {
            let that = this;
            that.pageSwiper.allowSlidePrev = false;
            that.pageSwiper.allowSlideNext = false;
            that.$nextTick(() => {
              window.scrollTo({
                top: document.body.scrollHeight,
                behavior: "smooth",
              });
            });
          },
          fromEdge: () => {
            let that = this;
            if (that.pageSwiper.previousIndex) {
              if (that.pageSwiper.previousIndex < that.pageSwiper.realIndex) {
                that.pageSwiper.allowSlidePrev = true;
                that.pageSwiper.allowSlideNext = true;
                that.$nextTick(() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                });
              }
            }
          },
        },
      };
    },
    bannerSwiperOptions() {
      return {
        // height: window.innerHeight,
        nested: true,
        grabCursor: true,
        autoplay: true,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      };
    },
  },
  mounted() {
    let that = this;
    that.getBannerItems();
  },
  methods: {
    handlePageNext() {
      let that = this;
      that.pageSwiper.slideNext();
    },
    getBannerItems() {
      let that = this;
      that.$API
        .getData("/content/common/list/" + that.baseInfo.bannerdata[0].size, {
          categoryId: that.baseInfo.bannerdata[0].id,
        })
        .then((res) => {
          if (res.code === 0) {
            that.bannerItems = res.data.filter(
              (item) => item.contentImgurlArray.length > 0
            );
          }
        });
    },
  },
};
</script>
