import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    meta: { title: "首页", model: "index" },
    name: "index",
    path: "/",
    component: (resolve) => {
      require(["../views/Index.vue"], resolve);
    },
  },
  {
    meta: { title: "分类列表", model: "main" },
    name: "main",
    path: "/main/:mid(\\d+)?",
    component: (resolve) => {
      require(["../views/Main.vue"], resolve);
    },
  },
  {
    meta: { title: "内容列表", model: "item" },
    name: "item",
    path: "/item/:mid(\\d+)?",
    component: (resolve) => {
      require(["../views/Item.vue"], resolve);
    },
  },
  {
    meta: { title: "内容详细", model: "info" },
    name: "info",
    path: "/info/:mid(\\d+)/:id(\\d+)",
    component: (resolve) => {
      require(["../views/Info.vue"], resolve);
    },
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default new VueRouter({
  history: true,
  routes: routes,
});
