<template>
  <el-header class="header" height="61px">
    <el-menu
      :default-active="menuActive"
      mode="horizontal"
      class="header-navbar"
      @select="handleMenuSelect"
    >
      <el-menu-item index="0"> 首页 </el-menu-item>
      <el-menu-item
        v-for="(item, index) in menuItems"
        :key="index"
        :index="item.id"
      >
        <template v-if="!item.children"> {{ item.categoryTitle }} </template>
        <el-submenu v-else :index="item.id">
          <template slot="title"> {{ item.categoryTitle }} </template>
          <el-menu-item-group
            v-for="(groItem, groIndex) in item.children"
            :key="groIndex"
            :style="{ width: 100 / item.children.length + 'vw' }"
          >
            <span slot="title"> {{ groItem.categoryTitle }} </span>
            <el-menu-item
              v-for="(subItem, index) in contentItems"
              v-if="subItem.categoryId === groItem.id"
              :key="index"
              :index="subItem.id"
            >
              <i
                v-if="subItem.contentAlias != ''"
                :class="['icon', 'icon-' + subItem.contentAlias]"
              >
              </i>
              {{ subItem.contentTitle }}
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu-item>
    </el-menu>
  </el-header>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "TtHeader",
  computed: {
    ...mapState({
      categoryItems: (state) => state.categoryItems,
    }),
    menuItems() {
      return this.categoryItems.filter((item) => item.categoryIswebnav === 1);
    },
  },
  data: () => ({
    contentItems: [],
    menuActive: "0",
  }),
  mounted() {
    let that = this;
    that.menuActive = that.$route.params.mid || "0";
    that.getContentItems();
  },
  methods: {
    ...mapActions(["setObj"]),
    getContentItems() {
      let that = this;
      that.$API
        .getData("/content/common/list", {
          categoryIsindex: 1,
        })
        .then((res) => {
          if (res.code === 0) {
            that.contentItems = res.data;
          }
        });
    },
    handleMenuSelect(index, indexPath) {
      let that = this;
      that.menuActive = index;
      if (index === "0") {
        that.setObj({
          metaInfo: null,
        });
        that.$router.push({ name: "index" });
      } else if (indexPath.length < 2) {
        that.setObj({
          metaInfo: {
            cid: index,
          },
        });
        that.$router.push({
          name: "item",
          params: { mid: indexPath[0] },
          query: { cid: index },
        });
      } else {
        that.setObj({
          metaInfo: {
            cid: indexPath[indexPath.length - 2],
          },
        });
        that.$router.push({
          name: "info",
          params: { mid: indexPath[0], id: index },
        });
      }
    },
  },
};
</script>
<style lang="scss">
.el-menu--horizontal {
  left: 0 !important;
  .el-menu--popup {
    width: 100vw;
    display: flex;
  }
  .el-menu-item-group {
    display: inline-block;
    .el-menu-item-group__title {
      margin: 0 20px;
      padding: 10px 0;
      border-bottom: 1px solid #eee;
    }
    ul {
      padding-left: 50px;
    }
  }
}
</style>
