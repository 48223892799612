import store from "@/store";
import api from "@/common/api";
import util from "@/common/util";
import page from "@/components/common/page";

let template = "1463846400000000000";
let scss = "style";
let fonturl = null;

if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}

const install = async function (Vue, opts = {}) {
  if (store.state.baseInfo && store.state.categoryItems) {
    template = store.state.baseInfo.template;
    scss = store.state.baseInfo.scss;
    fonturl = store.state.baseInfo.fonturl;
  } else {
    let loading = page.$loading();
    let res = await api.getData("/site/common/info", {
      siteDomain: window.location.hostname,
    });
    if (res.code === 0 && res.data) {
      let data = res.data;
      let baseInfo = {
        site: data.id,
        title: data.siteTitle,
        keywords: data.siteKeywords,
        description: data.siteDescription,
        domain: data.siteDomain,
        copyright: data.siteCopyright,
        recordno: data.siteRecordno,
        minilogo: data.siteMinilogo,
        logo: data.siteLogo,
        imgurl: data.siteImgurl,
        color: data.siteWebcolor,
        template: data.templateWebid,
        indextid: data.templateWebid,
        indextpl: data.siteWebindextpl,
        indexdata: data.siteWebindexdataArray,
        headerdata: data.siteWebheaderdataArray,
        bannerdata: data.siteWebbannerdataArray,
        footerdata: data.siteWebfooterdataArray,
        scss: data.siteWebcolor,
        fonturl: data.siteIconfonturl,
        favicon: data.siteFavicon,
      };
      store.dispatch("setObj", {
        baseInfo: baseInfo,
      });

      api.getData("/category/common/tree").then((res) => {
        if (res.code === 0) {
          store.dispatch("setObj", {
            categoryItems: res.data,
          });
        }
      });

      template = baseInfo.template;
      scss = baseInfo.scss;
      fonturl = baseInfo.fonturl;
    }
    loading.close();
  }
  const templates = require(`${"./" + template + ""}`);
  Vue.use(templates, { scss: scss });
  if (!!fonturl) util.loadCss(fonturl + ".css");
};

export default {
  install,
};
