import TtCommonHeader from "./common/Header";
import TtCommonFooter from "./common/Footer";
import TtCommonBanner from "./common/Banner";

import TtCategoryItem from "./category/Item";
import TtCategoryInfo from "./category/Info";
import TtContentList from "./content/List";
import TtContentItem from "./content/Item";
import TtContentInfo from "./content/Info";

const components = [
  TtCommonHeader,
  TtCommonFooter,
  TtCommonBanner,

  TtCategoryItem,
  TtCategoryInfo,
  TtContentList,
  TtContentItem,
  TtContentInfo,
];

if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}

const install = function (Vue, opts = {}) {
  components.forEach((component) => {
    Vue.component(component.name, component);
  });
};

export default {
  install,
  TtCommonHeader,
  TtCommonFooter,
  TtCommonBanner,

  TtCategoryItem,
  TtCategoryInfo,
  TtContentList,
  TtContentItem,
  TtContentInfo,
};
