import Vue from "vue";
import ErrorView from "./Error.vue";
import LoadingView from "./Loading.vue";

const ErrorConstructor = Vue.extend(ErrorView);
const LoadingConstructor = Vue.extend(LoadingView);

const page = {
  install: (Vue) => {},
  $error: (options) => {
    let instance = new ErrorConstructor({ data: options });
    instance.$mount();
    document.body.appendChild(instance.$el);
    return {
      close: () => {
        instance.close();
      },
      refresh: () => {
        instance.refresh();
      },
    };
  },

  $loading: (options) => {
    let instance = new LoadingConstructor({ data: options });
    instance.$mount();
    document.body.appendChild(instance.$el);
    return {
      close: () => {
        instance.close();
      },
      refresh: () => {
        instance.refresh();
      },
    };
  },
};
export default page;
