<template>
  <div v-if="visible" class="base-error">
    <div class="error-main">
      <div class="error-main__car">
        <div class="error-main__bord">
          <div class="error-main__code">{{ message.value }}</div>
          <div class="error-main__msg">{{ message.text }}</div>
          <a @click="close"> 关闭提示 </a>
          <a @click="refresh"> 刷新本页 </a>
        </div>
        <div class="error-main__wheel wheel_l"></div>
        <div class="error-main__wheel wheel_r"></div>
      </div>
      <div class="error-road"></div>
    </div>
  </div>
</template>
<script>
import $DICT from "@/common/dict";
export default {
  data: () => ({
    visible: true,
    code: 0,
    message: null,
  }),
  mounted() {
    if (!this.message) {
      this.message = $DICT.ERROR.find((item) => item.value === this.code);
    }
  },
  methods: {
    close() {
      this.visible = false;
    },
    refresh() {
      location.reload();
    },
  },
};
</script>
<style lang="scss">
.base-error {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background: url(/ucoss/assets/error/images/bottom.png) #32b7fc bottom repeat-x;
  z-index: 1000;
  .error-main {
    flex: 1;
    color: #fff;
    height: 350px;
    position: relative;
    background: url(/ucoss/assets/error/images/background.jpg) repeat-x;
    .error-main__car {
      position: relative;
      margin: 0 auto;
      width: 500px;
      height: 300px;
      z-index: 100;
      background: url(/ucoss/assets/error/images/pear.png) bottom;
    }
    .error-main__bord {
      position: relative;
      width: 285px;
      height: 170px;
      left: 200px;
      top: 20px;
      color: #ffba00;
      text-align: center;
      a + a {
        margin: 0 10px;
        color: #fff;
        cursor: pointer;
      }
    }
    .error-main__wheel {
      position: absolute;
      top: 275px;
      width: 70px;
      height: 70px;
      z-index: 100;
      background: url(/ucoss/assets/error/images/wheel.png);
      animation: animation-roating 3s linear infinite;
      &.wheel_l {
        left: 50px;
      }
      &.wheel_r {
        left: 280px;
      }
    }
    .error-main__code {
      font-size: 60px;
    }
    .error-main__msg {
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .error-road {
    position: absolute;
    bottom: -30px;
    width: 100%;
    height: 150px;
    z-index: 1;
    background: url(/ucoss/assets/error/images/roat.png) repeat-x;
    animation: animation-move 1s linear infinite;
  }
}

@keyframes animation-roating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}
@keyframes animation-move {
  from {
    background-position: 0;
  }
  to {
    background-position: -104px;
  }
}
</style>
