<template>
  <el-container direction="vertical" :class="[$route.name]">
    <tt-header :menu="menu"></tt-header>
    <ts-item v-if="menu.categoryWebitemtpl === 'Item'" :menu="menu"> </ts-item>
    <tt-common-footer :menu="menu"></tt-common-footer>
  </el-container>
</template>

<script>
import TsItem from "./Item";
import { mapState } from "vuex";
export default {
  name: "TtItem",
  components: {
    TsItem,
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
};
</script>
