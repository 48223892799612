<template>
  <el-container direction="vertical" :class="[$route.name]">
    <tt-common-header :menu="menu"></tt-common-header>
    <ts-info v-if="menu.categoryWebinfotpl === 'Info'" :menu="menu"> </ts-info>
    <tt-common-footer :menu="menu"></tt-common-footer>
  </el-container>
</template>

<script>
import TsInfo from "./Info";
import { mapState } from "vuex";
export default {
  name: "TtInfo",
  components: {
    TsInfo,
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
};
</script>
