<template>
  <el-header class="header">
    <el-menu
      :default-active="menuActive"
      mode="horizontal"
      class="header-navbar"
      @select="handleMenuSelect"
    >
      <el-menu-item index="0"> 首页 </el-menu-item>
      <el-menu-item
        v-for="(item, index) in menuItems"
        :key="index"
        :index="item.id"
      >
        <template v-if="!item.children"> {{ item.categoryTitle }} </template>
        <el-submenu v-else :index="item.id">
          <template slot="title"> {{ item.categoryTitle }} </template>
          <el-menu-item
            v-for="(subItem, index) in item.children"
            :key="index"
            :index="subItem.id"
          >
            {{ subItem.categoryTitle }}
          </el-menu-item>
        </el-submenu>
      </el-menu-item>
    </el-menu>
  </el-header>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "TtCommonHeader",
  data: () => ({
    menuActive: "0",
  }),
  computed: {
    ...mapState({
      categoryItems: (state) => state.categoryItems,
    }),
    menuItems() {
      return this.categoryItems.filter((item) => item.categoryIswebnav === 1);
    },
  },
  mounted() {
    let that = this;
    that.menuActive = that.$route.params.mid || "0";
  },
  methods: {
    ...mapActions(["setObj"]),
    handleMenuSelect(index, indexPath) {
      let that = this;
      that.menuActive = index;

      if (index === "0") {
        that.setObj({
          metaInfo: null,
        });
        that.$router.push({ name: "index" });
      } else {
        that.setObj({
          metaInfo: {
            cid: index,
          },
        });
        that.$router.push({
          name: "item",
          params: { mid: indexPath[0] },
          query: { cid: index },
        });
      }
    },
  },
};
</script>
